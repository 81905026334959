<template>
  <div class="list-info">
    <top-bar :title="'突发事件'" :left="true"></top-bar>
    <div class="search">
      <input v-model="selector.searchValue" type="search" value="搜索" placeholder="请输入事件发生位置" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="12">
          <p @click="control.dateBarShow = !control.dateBarShow" :style="{color:control.dateBarShow?'#387FF5':'#666666'}">
            发生时间
            <img :src="require(`@/assets/img/${control.dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="12">
          <p @click="control.rangeShow = !control.rangeShow" :style="{color:control.rangeShow?'#387FF5':'#666666' }">所属小区
            <img :src="require(`@/assets/img/${control.rangeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="control.dateBarShow">
      <van-row>
        <van-col span="8" @click="control.beginDateShow = !control.beginDateShow"><span>{{selector.startDates == ''?'最早':selector.startDates}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="control.endDateShow = !control.endDateShow"><span>{{selector.endDates== ''?'至今':selector.endDates}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <van-popup v-model="control.beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="control.beginDateShow = false"  type="date" title="选择年月日"  :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="control.endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="control.endDateShow = false" type="date" title="选择年月日"  :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="control.rangeShow" position="bottom"  >
      <van-picker title="所属小区" show-toolbar :columns="selector.subOptions" value-key="label" @confirm="rangeconfirm" @cancel="control.rangeShow = false"  />
    </van-popup>
    <div class="cont ">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="control.loading" :finished="!control.haveMore" finished-text="没有更多了" @load="onReachBottom" v-if="dataList.length > 0" offset="10">
  <!--        <div class="totalCount">共有&nbsp;{{totalCount}}&nbsp;条数据</div>-->
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>

          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.createUserName + ' | ' + (item.emergencyTime)}}
              </div>
              <div class="title-right">
                {{item.subareaStr}}
              </div>
            </div>

            <div class="content">
              <van-image :src="item.url||require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.address}}</div>
                <div class="bottom-text">{{item.des}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getbelongSubArea} from '@/utils/common'
import {newDate} from '@/utils/utils'
import { mapMutations } from 'vuex'
export default {
  name: "index",
  components :{
    topBar
  },
  data() {
    return {
      id:'',
      totalCount:0,
      dataList:[
        // {createUserName:'张三',createTime:'2020-1-2',detailPlace:'南方第一实验小学',id:'1',url:'',address:'白云大厦',emergencyContent:'紧急事件的描述内容'},
        // {createUserName:'李四',createTime:'2020-2-2',detailPlace:'南方第一实验小学',id:'2',url:'',address:'蓝天小区',emergencyContent:'紧急事件的描述内容'},
      ],
        //当前页码
        page: 1,
        //每页展示的记录数
        limit: this.$globalData.limit,
      selector:{
        //创建时间
        startDates: '',
        endDates: '',
        //搜索
        searchValue: '',
        //所属小区
        subTitle: '所属小区',
        subOptions: [],
        sub: '',
      },
      control:{
        searchBarHidden:true,
        dateBarShow:false,
        rangeShow:false,
        haveMore: true,
        beginDateShow:false,
        endDateShow:false,
        loading:false

      },
      pullLoading: false,
      hasSubmit: false,
      //列表是否为空
      listIsNull: false,
      //用户的组织结构id
      orgid: '',
      msg: {
        title: '空空如也',
        text: '暂时没有相关数据',
      },
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/emergency-info', query: {id: id}})
    },
    onReachBottom: function () {
      if (this.control.haveMore) {
        this.page++;
        this.getDataList();
      }
    },
    getDataList () {
      // this.page++
      // this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/emergency/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          searchValue: this.selector.searchValue, // 搜索框内容,以名字作为查询条件
          startTime: this.selector.startDates, //开始时间
          endTime: this.selector.endDates,   //结束时间
          subarea: this.selector.sub,      //所属小区id
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.control.loading = false;
          this.$toast.clear()
          if(this.dataList.length< data.page.totalCount){
            this.control.haveMore=true
          }else{
            this.control.haveMore=false
          }
        }else{

        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    //获取所属小区下拉列表的值
    getSubareaOptions () {
      var that = this
      getbelongSubArea(
          this.$globalData.userInfo.orgId,
          //回调函数
          function (res) {
            that.selector.subOptions= res
          })
    },
    rangeconfirm (val) {
      this.selector.sub = val.value
      this.page = 1
      this.dataList = []
      this.getDataList()
      this.control.rangeShow = false
    },
    beginDateConfim (value) {
      let d =  newDate(this.selector.endDates)
      if (this.selector.endDates != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selector.startDates = formatterDate(value)
      this.control.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selector.beginDate)
      if (this.selector.startDates != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selector.endDates = formatterDate(value)
      this.control.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 1
      this.getDataList()
    },
    dateClose () {
      selector.startDates= ''
      selector.endDates=''
    },
    typeConfirm (value,index) {
      //debugger
      this.page = 1
      this.dataList = []
      this.currentType = value.value
      this.getDataList()
      this.typeShow = false
    },

    goAdd () {
      this.$router.push('/emergency-info')
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch() {
      this.page = 1
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 1
      this.dataList = []
      this.selector.searchValue = ''
      this.getDataList()
    },
  },
  created () {
    // this.$toast.loading({
    //   duration: 0,
    //   message: '加载中...',
    //   forbidClick: true,
    // });
    this.page = 1
    this.getDataList()
    this.getSubareaOptions()
  }
}
</script>

